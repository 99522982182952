import * as React from 'react'
import PricingBanner from '../components/PricingBanner'
import Layout from '../components/Layout'
import SupportItem from '../components/SupportItem'
import Seo from "../components/Seo"

const SupportPage = () => {
    return (
        <Layout>
            <Seo 
                title="Support"
                description="Support for your every ride"
            />
            <div className='flex items-center justify-center'>
                <h1 className="my-8 text-4xl font-bold text-left lg:text-center md:my-12 text-rickshaw-blue">
                    Support for every ride
                </h1>
            </div>

            <ul className="mx-4 border-t-2 border-b-2 divide-y-2 lg:mx-auto lg:max-w-screen-lg divide-rickshaw-blue border-rickshaw-blue">
                <SupportItem title="Where does Rickshaw live?">
                    <p>Rickshaw has partnered with Linode and uses their infrastructure to run the platform at the <b>Dallas</b> data centre.</p>
                    <p className="mt-2">Linode is a cloud platform that developer's trust. Anomaly has deployed several production workloads on their infrastructure over the past decade.</p>
                </SupportItem>
                <SupportItem title="How does Rickshaw secure my API Keys?">
                    <p>We take security extremely seriously, all your API keys are encrypted at rest.</p>
                    <p className="pt-2">
                        Should there be an issue, we will be forthcoming and reach out to you so you can cycle your API keys immediately.
                    </p>
                </SupportItem>
                <SupportItem title="How is my data protected?">
                    <p>We work with Linode's security policies to ensure that your data is protected the best we can.</p>
                </SupportItem>
                <SupportItem title="What does Rickshaw use to process payments?">
                    <p>
                        Stripe of course <span aria-label="Smile" role="img">😀</span>!{' '}
                        We're Stripe partners and proudly use the leading payment provider.
                    </p>
                    <p className="mt-2">Rickshaw will contribute <b>1% of your spend</b> to remove CO₂ from the atmosphere.</p>
                </SupportItem>
                <SupportItem title="Tell me more about Anomaly">
                    <p>
                        <a href="https://www.anomaly.net.au" className='underline text-rickshaw-blue'>Anomaly Software</a>{' '}
                        is an Australian software company and a Stripe{' '}
                        <a className='underline text-rickshaw-blue' href="https://stripe.com/partners/anomaly">expert services partner</a>.
                    </p>
                    <p className="mt-2">Rickshaw was born out of numerous customers asking us to build custom communication integrations for their payment needs.</p>
                    <p className="mt-2">Rickshaw is also an integration part of all SaaS ventures at Anomaly.</p>
                </SupportItem>
                <SupportItem title="I have an idea!">
                    <p>We love to hear from our customers and want to constantly make Rickshaw better.</p>
                    <p className="mt-2">If you have an idea on how Rickshaw could serve you better, please reach out and we will do what we can to make your idea part of the platform.</p>
                </SupportItem>
            </ul>

            <PricingBanner />
        </Layout>
    );
};

export default SupportPage;
