import React, { useState } from 'react'

const SupportItem = ({ title, children }) => {

    const [accordionOpen, setAccordionOpen] = useState(false);
    return (
        <li className='py-6 xl:px-0 lg:mx-auto'>
            <button
                onClick={() => setAccordionOpen((o) => !o)}
                className='flex flex-row items-center justify-between w-full px-2 text-rickshaw-blue lg:px-4'
            >
                <p className='text-xl font-semibold'>{title}</p>
                <div>
                    <svg className={`${!accordionOpen ? 'block' : 'hidden'} h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    <svg className={`${accordionOpen ? 'block' : 'hidden'} h-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </button>
            <div className={`${accordionOpen ? 'block' : 'hidden'} mt-4 px-4 text-gray-500`}>
                { children }
            </div>
        </li>
    );
};

export default SupportItem;
